.contact-sbumissions {
    padding: $padding-large;
    border-bottom: 1px solid $border-color;

    h3 {
        font-size: $font-size-large;
        color: $heading-color;
    }

    .submission {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $padding-base 0;

        &__controls {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        &__btn-delete {
            font-size: $font-size-medium;
            cursor: pointer;
            transition: color .2s ease-in-out;

            &:hover {
                color: lighten($text-color, 20%);
            }
        }

        & + .submission {
            border-top: 1px solid $border-color;
        }

        strong {
            font-size: $font-size-medium;
        }

        p {
            margin: $margin-small 0;
            color: $text-color;
            font-size: $font-size-base;
        }
    }
}