.projects {
  padding: $padding-large;

  &__btn-add {
    text-decoration: none;
    font-size: $font-size-medium;
    color: $blue-color;
    float: right;
    transition: color .2s ease-in-out;

    &:hover {
      color: lighten($blue-color, 10%);
    }
  }

  h3 {
    font-size: $font-size-large;
    color: $heading-color;
    margin-bottom: $margin-medium;
  }

  .project {
    display: flex;
    flex-direction: column;
    margin-bottom: $margin-large;
    transition: transform .5s ease, opacity .5s ease;

    &__deleting {
      opacity: 0;
      transform: scale(0.9);
    }

    img {
      width: 100%;
      height: 30rem;
      object-fit: cover;
      border-radius: 8px;
      margin-right: $margin-medium;
      object-position: top;
    }

    .project-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $margin-base;
      &__content {
        h4 {
          font-size: $font-size-medium;
          color: $heading-color;
        }
      }
      &__btn-edit {
        &,
        &:link,
        &:visited {
          margin: $margin-small 0;
          color: $blue-color;
          font-size: $font-size-base;
          background-color: transparent;
          border: none;
          display: block;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
