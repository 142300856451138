.link-input {
    position: relative;
    &__description {
        font-size: $font-size-medium;
    }

  &__link {
    position: relative;
    overflow: hidden;
  }

  &__container {
    margin-top: 1rem;
    border-radius: 8px;
    overflow: hidden;
    height: min-content;
    position: relative;
  }

  &__input {
    width: 100%;
    background-color: $color-gray-dark-1;
    border: none;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1rem 1.5rem;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    font-size: 2rem;
    background-color: $color-gray-dark-1;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    padding: 1rem;
    transition: background 0.2s ease-in-out;

    &:hover {
      background-color: $color-gray-dark-2;
    }
  }

  &__message {
    position: absolute;
    z-index: 10;
    bottom: -3rem;
    right: 0;
    font-size: $font-size-small;
    background-color: rgba(0, 0, 0, .7);
    color: $white-color;
    padding: .5rem 1rem;
    border-radius: 1.6rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in-out, visibility 0s linear 0s;

    &--show {
        opacity: 1;
        visibility: visible;
    }

    &--hide {
        transition-delay: 0s, .2s;
        opacity: 0;
        visibility: hidden;
    }
  }
}
