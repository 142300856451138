*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 10px
}

body {
    box-sizing: border-box;
    background-color: $primary-color;
}