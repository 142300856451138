.profile-page {
    text-align: center;

    &__profile {
        &-image {
            width: 12rem;
            aspect-ratio: 1;
            border-radius: 50%;
            object-fit: cover;
            margin: $margin-base auto;
        }
    
        &-name {
            font-size: $font-size-large;
            color: $heading-color;
            margin-top: $margin-small;
        }
    
        &-profession {
            font-size: $font-size-medium;
            color: $secondary-color;
            margin-bottom: $margin-large;
        }
    }

    &__bio {
        margin-bottom: $margin-large;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-button {
            font-size: $font-size-medium;
            width: 100%;
            padding: $padding-base $padding-medium;

            &:not(:last-child) {
                margin-bottom: $margin-base;
            }
        }

        &-text {
            font-size: $font-size-base;
            margin-bottom: $margin-base;
        }
    }

    &__projects {
        &-title {
            font-size: $font-size-large;
            margin-bottom: $margin-base;
            text-align: left;
        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $margin-medium;
        }

        &-item {
            overflow: hidden;
            text-decoration: none;
            color: inherit;
            
            img {
                box-shadow: $box-shadow-small;
                border-radius: 8px;
                width: 100%;
                height: 12rem;
                object-fit: cover;
                transition: box-shadow .2s ease-in-out;
                object-position: top;
            }

            p {
                font-size: $font-size-base;
                margin-top: $margin-small;
                font-weight: 500;
                text-align: left;
            }

            &:hover > img {
                box-shadow: $box-shadow-medium;
            }
        }
    }
}
