.post {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;
  }

  &__action {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    align-items: center;
    column-gap: 1rem;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 1rem 2rem;
    color: $color-blue;
    font-size: 2rem;
    transition: background .2s ease-in-out;
    border-radius: 8px;

    &:hover {
        background-color: #ccc;
      }
  }

  &__action-icon {
    text-decoration: none;
    transition: all .2s ease-in-out;
    padding: 1rem 2rem;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition-delay: .2s;

    &-container {
      transition: all .2s ease-in-out;
      grid-row: 1;
      grid-column: 1;

      & svg {
        padding: 0;
      }

      &--scale-down {
        transform: scale(0.8);
      }

      &--scale-up {
        transition-delay: .2s;
        transform: scale(1.2);
      }
    }

    &--red {
        color: #DC143C;
    }

    &--hide {
        transition: all .2s ease-in-out;
      visibility: hidden;
      opacity: 0;
    }
  }

  &__icon {
    &--heart {
      &-active {
        color: red;
      }
    }
  }
}
