.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  touch-action: pan-y;
  user-select: none;

  &__indicator {
    position: absolute;
    top: 1rem;
    right: 2rem;
    color: $white-color;
    font-weight: 700;
    font-size: $font-size-large;
    background-color: rgba(0, 0, 0, 0.5);
    padding: $padding-base $padding-medium;
    z-index: 10;
    transition: opacity 0.2s ease-in-out, visibility 0s linear .2s;
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: $white-color;
    font-weight: 700;
    font-size: $font-size-large;
    padding: $padding-base $padding-medium;
    border-radius: 50%;
    border: none;
    z-index: 10;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out, visibility 0s linear 2s;

    &--next {
      right: 1rem;
    }

    &--prev {
      left: 1rem;
    }
  }

  &--show-actions > &__indicator,
  &--show-actions > &__button {
    transition-delay: .2s, 0s;
    opacity: 1;
    visibility: visible;
  }

  &--hide-actions > &__indicator,
  &--hide-actions > &__button {
    transition-delay: 2s, 2.2s;
    opacity: 0;
    visibility: hidden;
  }

  &__items {
    display: flex;
    overflow-x: auto;
    transition: transform 0.3s ease-in-out;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    min-width: 100%;
  }

  &__img {
    width: 100%;
    height: 30rem;
    object-fit: cover;
    object-position: top;
    display: block;
  }
}
