.project-images {
    flex: 1;
}

.uploaded-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: $margin-large 0;

    &__item {
        position: relative;
        border: 1px solid $border-color;
        border-radius: 8px;
        overflow: hidden;

        &:hover .uploaded-gallery__controls {
            opacity: 1;
        }
    }

    &__img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }

    &__controls {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        background-color: rgba(0, 0, 0, .5);
        color: $white-color;
        opacity: 0;
        transition: opacity .3s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
}