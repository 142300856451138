body {
  font-family: $font-stack;
  line-height: 1.6;
  color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
  color: $text-color;
  font-weight: 600;
  line-height: 1.2;
}

.heading {
  &-primary {
    font-size: 2.5rem;
    font-weight: 700;
  }

  &-secondary {
    font-size: 2rem;
    font-weight: 600;
  }

  &-tertiary {
    font-size: 1.7rem;
    font-weight: 400;
  }

  &--blue {
    color: $color-blue;
  }
}

.description {
  font-size: 1.4rem;

  &--sm {
    font-size: 1.2rem;
  }

  &--blue {
    color: $color-blue;
  }
  
  &--bold {
    font-weight: 500;
  }
}

.text {
  &--secondary {
    font-size: 1.2rem;
  }
}
