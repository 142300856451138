.edit-project {
  min-height: calc(100vh - 30rem);
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__tabs {
    width: 100%;
  }

  &__tab {
    width: 50%;
    background-color: transparent;
    border: none;
    font-size: $font-size-large;
    cursor: pointer;
    padding: $padding-small $padding-base;
    border-bottom: 2px solid transparent;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

    &:not(:last-child) {
      border-right: 2px solid $border-color;
    }

    &:hover {
      background-color: $border-color;
    }

    &--active {
      border-bottom: 2px solid $blue-color;
    }
  }

  &__actions {
    display: flex;

    button {
      flex: 1;
    }
  }
}
