.share {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 2rem;
  }

  &__title {
    grid-column: 1/-1;
  }

  &__button {
    &,
    &:link,
    &:visited {
      width: 100%;
      text-decoration: none;
      color: #000;
      border: 1px solid $color-gray-dark-1;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transition: all .2s ease-in-out;
    }

    &:hover,
    &:focus {
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        transform: translateY(-3px) scale(1.03);
    }
  }

  &__button-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  &__button-title {
    font-size: 2rem;
    font-weight: 700;
  }

  &__button-description {
    font-size: 1.5rem;
    color: $color-blue;
  }
}
