.profile {
    text-align: center;
    padding: $padding-large;

    .profile-image {
        width: 10rem;
        aspect-ratio: 1;
        border-radius: 50%;
        margin-bottom: $margin-base;
        object-fit: cover;
    }

    h2 {
        font-size: $font-size-large;
    }

    p {
        font-size: $font-size-base;
        color: $text-color;
    }
}