.container {
    max-width: 64rem;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    background-color: $white-color;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
    border-radius: 8px;

    @include respond(screen-sm) {
        min-height: 100vh;
    }
}