.project-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $padding-large 0;

    &__input {
        border: none;
        border-bottom: 1px solid $border-color;
        resize: none;
        font-size: $font-size-medium;
        padding: $padding-small $padding-base;
        margin: $margin-small 0;
        font-family: inherit;
        transition: border .2s ease-in-out;

        &:not(:last-child) {
            margin-bottom: $margin-base;
        }

        &:focus {
            outline: none;
            border-bottom: 1px solid $secondary-color;
        }
    }
}