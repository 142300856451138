.button {
  padding: 0.8rem 1.6rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &--primary {
    &,
    &:link,
    &:visited {
      background-color: $primary-color;
      color: $secondary-color;
      box-shadow: $box-shadow-small;
      text-decoration: none;
      font-size: $font-size-base;
    }

    &:hover {
      background-color: darken($primary-color, 10%);
      box-shadow: $box-shadow-medium;
    }

    &:active {
      background-color: darken($primary-color, 15%);
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &--secondary {
    background-color: $secondary-button-color;
    color: $text-color;

    &:hover {
      background-color: darken($secondary-button-color, 3%);
    }

    &:active {
      background-color: darken($secondary-button-color, 15%);
    }
  }

  &--blue {
    background-color: $blue-color;
    color: $white-color;

    &:hover {
      background-color: lighten($blue-color, 10%);
    }

    &:active {
      background-color: lighten($blue-color, 15%);
    }
  }

  &--transparent {
    background-color: transparent;

    &:hover {
      background-color: $secondary-button-color;
    }

    &:focus {
      background-color: darken($secondary-button-color, 10%);
    }
  }

  &--disabled {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $secondary-button-color;
      cursor: not-allowed;
    }
  }

  &:focus {
    outline: none;
  }
}

.btn {
  &,
  &:link,
  &:visited {
    display: block;
    text-decoration: none;
    padding: 1rem 2rem;
    transition: all 0.2s ease-in-out;
    border: none;
    cursor: pointer;
    line-height: inherit;
  }

  &__primary {
    width: 100%;
    background-color: $color-gray-light-2;
    color: #000;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 8px;
    font-weight: 700;

    &:hover,
    &:focus {
      filter: brightness(90%);
    }
  }

  &-submit {
    background-color: $color-success;
    color: #fff;
    border-radius: 16px;
    font-size: 1.5rem;
    font-weight: 700;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    &:hover {
      background-color: $color-success-hover;
      transform: translateY(-3px) scale(1.03);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }
  }

  &-transparent {
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: 700;
  }
}
