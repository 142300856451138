.page-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    color: $color-gray-dark-2;

    &-text {
        font-size: 2rem;
    }

    &-icon {
        font-size: 5rem;
    }
}