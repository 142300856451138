.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: $padding-xlarge 0;

    .container {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 2rem;

        main {
            flex: 1;
        }
    }

    @include respond(screen-sm) {
        padding: 0;
    }
}