.contact-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white-color;
    padding: $padding-large;
    width: 100%;
    max-width: 80rem;
    border-radius: 8px;
    box-shadow: $box-shadow-small;
    z-index: 1000;
    font-size: $font-size-medium;

    &__btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }

    &__email {
        
        &--content {
            display: inline;
            text-decoration: underline;
        }
    }

    p {
        padding: $padding-base;
        &:not(:last-of-type) {
            border-bottom: 1px solid $border-color;
        }
    }

    button {
        float: right;
    }
}