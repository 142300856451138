.login-page {
    min-height: calc(100vh - 30rem);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__input {
        border: none;
        border-bottom: 1px solid $border-color;
        font-size: $font-size-medium;
        padding: $padding-base $padding-medium;

        &:focus {
            outline: none;
        }
    }
    
    &__btn {
        background-color: $blue-color;
        color: $white-color;
        font-size: $font-size-large;
        padding: $padding-base $padding-medium;
        border: none;
        font-weight: 700;
        cursor: pointer;
        transition: background .2s ease-in-out;

        &:hover {
            background-color: lighten($blue-color, 10%);
        }

        &:focus {
            outline: none;
        }
    }
}