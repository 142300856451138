.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &--small > &__spinner {
    width: 2rem;
    height: 2rem;
    border-width: .3rem;
  }

  &--medium > &__spinner {
    width: 4rem;
    height: 4rem;
    border-width: .4rem;
  }

  &--large > &__spinner {
    width: 6rem;
    height: 6rem;
    border-width: .5rem;
  }

  &__spinner {
    border: .4rem solid transparent;
    border-top-color: $blue-color;
    border-radius: 50%;
    animation: spin .8s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}