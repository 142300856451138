// Color Variables
$primary-color: #f7fafc;
$secondary-color: #4f7396;
$blue-color: #1A80E5;
$white-color: #fff;
$pink-color: #FF69B4;
$light-green-color: #D0FFC4;
$green-color: #74A751;
$border-color: #e0e0e0;
$heading-color: #333;
$secondary-button-color: #E8EDF2;
$text-color: #666;
$invalid-color-light: #ffe6e6;
$invalid-color-dark: #ff4d4d;

// Font Variables
$font-stack: "Roboto", sans-serif;

// Font Size Variables
$font-size-small: 1.2rem; // 12px
$font-size-base: 1.4rem; // 14px
$font-size-medium: 1.6rem; // 16px
$font-size-large: 2rem; // 20px
$font-size-xlarge: 2.4rem; // 24px
$font-size-xxlarge: 3rem; // 30px

// Margin Variables
$margin-small: 0.5rem; // 5px
$margin-base: 1rem; // 10px
$margin-medium: 1.5rem; // 15px
$margin-large: 2rem; // 20px
$margin-xlarge: 3rem; // 30px

// Padding Variables
$padding-small: 0.5rem; // 5px
$padding-base: 1rem; // 10px
$padding-medium: 1.5rem; // 15px
$padding-large: 2rem; // 20px
$padding-xlarge: 3rem; // 30px

// Box-Shadow Variables
$box-shadow-small: 0 1px 3px rgba(0, 0, 0, 0.1);
$box-shadow-medium: 0 3px 6px rgba(0, 0, 0, 0.15);
$box-shadow-large: 0 10px 20px rgba(0, 0, 0, 0.2);

// CSS Custom Properties
:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --secondary-button-color: #{$secondary-button-color};
  --blue-color: #{$blue-color};
  --white-color: #{$white-color};
  --pink-color: #{$pink-color};
  --border-color: #{$border-color};
  --heading-color: #{$heading-color};
  --text-color: #{$text-color};
  --font-stack: #{$font-stack};
  --invalid-color-light: #{$invalid-color-light};
  --invalid-color-dark: #{$invalid-color-dark};

  --font-size-small: #{$font-size-small};
  --font-size-base: #{$font-size-base};
  --font-size-medium: #{$font-size-medium};
  --font-size-large: #{$font-size-large};
  --font-size-xlarge: #{$font-size-xlarge};
  --font-size-xxlarge: #{$font-size-xxlarge};

  --margin-small: #{$margin-small};
  --margin-base: #{$margin-base};
  --margin-medium: #{$margin-medium};
  --margin-large: #{$margin-large};
  --margin-xlarge: #{$margin-xlarge};

  --padding-small: #{$padding-small};
  --padding-base: #{$padding-base};
  --padding-medium: #{$padding-medium};
  --padding-large: #{$padding-large};
  --padding-xlarge: #{$padding-xlarge};

  --box-shadow-small: #{$box-shadow-small};
  --box-shadow-medium: #{$box-shadow-medium};
  --box-shadow-large: #{$box-shadow-large};
}

$color-gray-light-1: #dde6ee;
$color-gray-light-2: #e8edf2;

$color-gray-dark-1: #d1dbe8;
$color-gray-dark-2: #a9a9a9;

$color-blue: #4f7396;

$color-success: #1a80e5;
$color-success-hover: #1a86f1;

$color-invalid-light: #ffe6e6;
$color-invalid-bright: #ff4d4d;
