.form {
    width: 100%;
    min-height: calc(100vh - 34rem);
    display: flex;
    flex-direction: column;

    &__inputs {
        flex: 1;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: $margin-large;

        button {
            width: 100%;
        }
    }
}