@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin respond($break-point) {
  @if $break-point == screen-sm {
    @media only screen and (max-width: 40em) {
      @content;
    }
  }
}
