.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white-color;
    padding: $padding-large;
    border-radius: 8px;
    box-shadow: $box-shadow-large;
    z-index: 1000;
    width: 90%;
    max-width: 30rem;
    text-align: center;

    &__content {
        position: relative;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        font-size: $font-size-large;
        cursor: pointer;
        color: $text-color;
    }

    &__icon {
        font-size: $font-size-xxlarge;
        color: $secondary-color;
        margin-bottom: $margin-base;
    }

    &__message {
        font-size: $font-size-medium;
        color: $text-color;
        margin-bottom: $margin-medium;
    }
}