.drag-drop {

    &__drop-zone {
        border: 2px dashed #ccc;
        border-radius: 8px;
        padding: $padding-large;
        text-align: center;
        cursor: pointer;

        input {
            display: none;
        }

        p {
            font-size: $font-size-base;
        }
    }

    &__icon {
        font-size: 7rem;
        margin-bottom: $margin-base;
        color: $border-color;
    }
}