.footer {
    text-align: center;
    padding: $padding-large;
    margin-top: $margin-large;

    &__icons {
        margin-bottom: $margin-base;

        .footer__icon-link {
            margin: 0 1rem;
            font-size: $font-size-xlarge;
            color: $secondary-color;

            &:hover {
                color: lighten($secondary-color, 10%);
            }
        }
    }

    &__copyright {
        font-size: $font-size-small;
    }
}