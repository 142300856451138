.project-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__url {
    &,
    &:link,
    &:visited {
      font-size: $font-size-base;
      margin-bottom: $margin-large;
      color: $blue-color;
      text-decoration: none;
      transition: color .2s ease-in-out;
    }

    &:hover {
        color: lighten($blue-color, 10%);
    }
  }

  &__error {
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: $font-size-large;
    margin-top: $margin-large;
  }
}
