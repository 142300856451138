.post-author {
    &__user {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__img {
        width: 6rem;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
    }

    &__username {
        font-size: $font-size-medium;
        font-weight: 600;
        line-height: 1.2;
    }

    &__date {
        font-size: $font-size-base;
        color: $secondary-color;
    }

    &__description {
        font-size: $font-size-base;
        margin-top: $margin-base;
        margin-bottom: $margin-large;
        font-weight: 500;
    }
}