.header {
    width: 100%;
    background-color: $white-color;
    color: $text-color;
    padding: $padding-medium;
    @include flex-center;
    justify-content: flex-start;
    position: relative;

    h1 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        font-size: $font-size-xlarge;
        color: $text-color;
    }

    &__btn {
        color: $text-color;
        font-size: $font-size-xlarge;
        background-color: transparent;
        border: none;
        cursor: pointer;
        line-height: 1;

        &:focus {
            outline: none;
        }
    }
}