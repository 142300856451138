.new-message {

    &__amount {
        background-color: $pink-color;
        color: $white-color;
        padding: $padding-small $padding-base;
        border-radius: 50%;
        font-size: $font-size-small;
        line-height: 1;
    }

    &__btn {
        background-color: $light-green-color;
        color: #74A751;

        &:hover {
            background-color: darken($light-green-color, 5%);
        }
    }
}