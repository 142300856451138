.input {
    width: 100%;
    padding: $padding-base;
    border: 1px solid $border-color;
    border-radius: 8px;
    margin-bottom: $margin-base;
    font-size: $font-size-medium;
    resize: none;
    transition: all .2s ease-in-out;
    font-family: inherit;

    &--invalid {
        background-color: $invalid-color-light;
        border: 1px solid $invalid-color-dark;
    }

    &:focus {
        outline: none;
    }
}